.section {

    background-position: center;
    background-size: cover;
}

.fp-tableCell {
    position: relative;
}

.section__wrapper {
    height: 100%;
    width: 100%;
    background:linear-gradient(90deg, rgba(0,0,0,0.3841911764705882) 0%, rgba(0,0,0,0.2777485994397759) 70%, rgba(0,0,0,0.17130602240896353) 100%);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 0;
}


.section__content {
    margin-left: auto;
    margin-right: auto;



}

.section__details {

    min-height: 60vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.section__description {
    font-family: "roboto", serif;
    width: 100%;
    font-size: 16px;
}

.section__tag {
    position: absolute;
    font-size: 1.375rem;
    font-weight: bold;
    top: calc(20vh + 5px);
    font-family: "roboto", serif;
}

.section__tag:before {
    content: '';
    position: absolute;
    width: 6vw;
    height: 1px;
    left: calc(-6vw - 5px);
    top: 50%;
    background-color: #fff;
}

.section__title {
    font-size: 30px;
    margin: 25px 0px;
    font-weight: bold;

}
.section__title a{
    color: #fff;
}

section__tag .tag__title {
    font-family: "roboto", serif;
}

.section_hLine {
    width: 112px;
    height: 1px;
    background: #ffffff;
    margin-bottom: 45px;
}

@media (min-width: 1000px) {
    .section__description {
        width: 86%;
        font-size: 20px;
    }

    .section__title {
        font-size: 50px;

    }

    .section__details {
        width: 60%;
    }
}


@media (max-width: 1200px) {
    .section__tag {
        /*left: calc(20vh + 10px );*/
        left: calc(20vw + 100px);
    }

    .section__tag:before {
        width: 57px;
        left: -68px;
    }
}


@media (max-width: 1400px) {
    .section__tag {
        /*left: calc(20vh + 10px );*/
        left: calc(6vw + 100px);
    }

    .section__tag:before {
        width: 57px;
        left: -68px;
    }
}


/*.section.fp-section.fp-table.active.fp-completely:after{*/
/*.section.fp-section.fp-table:after{*/
/*    content: "";*/
/*    position: absolute;*/
/*    left: 0;*/
/*    top: 0;*/
/*    width: 100vw;*/
/*    height: 100vh;*/
/*    background: rgb(0,0,0);*/
/*    opacity: 0.1;*/
/*    background: linear-gradient(90deg, rgba(0,0,0,0.2721463585434174) 0%, rgba(0,0,0,0.0032387955182072714) 31%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%);*/

/*}*/
.section.fp-section {
    overflow: hidden;
}

/*.section.fp-section.fp-table:before{*/
/*    content: '';*/
/*    position: absolute;*/
/*    top: -2%;*/
/*    left: -2%;*/
/*    height: 105%;*/
/*    width: 105%;*/
/*    background: rgba(0, 0, 0,0);*/
/*    z-index: 0;*/


/*}*/
/*.section.fp-section.fp-table:before{*/
/*    !*opacity: 1;*!*/
/*}*/
/*.section.fp-section.fp-table.active:before{*/

/*    !*-webkit-animation: fade-in 1s cubic-bezier(.44,.55,.65,.77);*!*/
/*    !*animation: fade-in 500ms ease-out ;*!*/
/*    !*opacity: 0;*!*/
/*}*/
/*.section.fp-section.fp-table.active.fp-completely:before{*/
/*    filter: blur(0px);*/
/*    !*-webkit-animation: fade-out 500ms cubic-bezier(.44,.55,.65,.77);*!*/
/*    !*animation: fade-out 500ms ease-out ;*!*/
/*    !*opacity: 1;*!*/

/*    !*opacity: 0;*!*/
/*    background: rgba(0, 0, 0,0);*/
/*}*/
/*.section.fp-section.fp-table.fp-completely:before{*/
/*    !*filter: blur(1px);*!*/
/*    !*-webkit-animation: fade-out 500ms ease-in;*!*/

/*    animation: fade-out 500ms ease-in-out forwards ;*/


/*}*/

/*!*@-webkit-keyframes fade-out {*!*/
/*!*    0% {*!*/
/*!*        opacity: 0;*!*/
/*!*    }*!*/

/*!*    100% {*!*/
/*!*        opacity: 1;*!*/
/*!*    }*!*/
/*!*}*!*/
/*@keyframes fade-out {*/
/*    from{*/
/*        background: rgba(0, 0, 0,1);*/
/*    }*/

/*    to {*/
/*        background: rgba(0, 0, 0,0);*/
/*    }*/
/*}*/


/*@-webkit-keyframes fade-in {*/
/*    0% {*/
/*        opacity: 1;*/
/*    }*/

/*    100% {*/
/*        opacity: 0;*/
/*    }*/
/*}*/
/*@keyframes fade-in {*/
/*    0% {*/
/*        opacity: 1;*/
/*    }*/

/*    100% {*/
/*        opacity: 0;*/
/*    }*/
/*}*/
@media (max-height:720px) {
.section__title {
    font-size: 25px;
}
.section__description{
    font-size: 15px;
}
.section_hLine{
    margin-bottom: 30px;
}
.section__details{
    margin-top: 35px;
}
}