
.heroSection{
    background: #ffffff;
    position: relative;
}
.section.heroSection.fp-section.fp-table .hero__scrollDown{
    animation: intro-Y 500ms forwards;
}
.section.heroSection.fp-section.fp-table.active.fp-completely .hero__scrollDown {
    animation: introY 500ms forwards;
}
.hero__bgTitle{
    font-size: 200px;
    text-align: center;
    font-family: 'Montserrat Alternates', serif;
    font-weight: 600;
}

.hero__scrollDown{
    cursor: pointer;
    font-family: "roboto", serif;
    background: none;
    border: none;
    outline: 0;
    font-size: 20px;
    position: absolute;
    bottom: 58px;
    left:calc(50% - 57px) ;
}

.hero__scrollDown .tag--bold{
    font-weight: bold;
}
.hero__scrollDownImg{
    position: absolute;
    left: calc(50% - 2px);
    top: 24px;
}


.x0.x1.x2 ,.x0.x1.x2>div,.x0.x1.x2>div>div{
    width: 100% !important;
}
.x0.x1.x2 span{
    width: 100%;
    text-align: center;
}