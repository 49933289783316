




@keyframes introY {
    from {
        opacity: 0;
        transform: translateY(+100px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes intro-Y {
    from {

        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(+100px);
    }
}




@keyframes introY-Scroll {
    from {
        transform: translateY(+100px);
    }
    to {
        transform: translateY(0);
    }
}
@keyframes intro-Y-Scroll  {
    from {

        transform: translateY(0);
    }
    to {
        transform: translateY(+100px);
    }
}


@keyframes scrollDot {
    from {
        transform: translateY(0px);
    }
    60%{
        transform: translateY(60px);
    }
    to {
        transform: translateY(0px);
    }
}
*{

    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    font-family: 'Montserrat Alternates', sans-serif;
    background: #00050f;
    color:#000000;
    overflow-x: hidden;

}
#root{
    position: relative;
}

/*Responsive width of elements*/


.go-nav__list{
    max-width:970px ;

}
.go-nav__list {
    width: 83%;
}

.go__wrapper{
    width: 80.68354%;
    margin-left: auto;
    margin-right: auto;


}
#root{
    overflow: hidden;
}

/*// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
    .go__wrapper{
        width: 87.34177%;
    }
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
    .go__wrapper{
        width: 87.34177%;
    }
}


/*// Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1000px) {
    .go__wrapper{
        width: 74.68354%;
    }
}

@media (min-width: 1200px) {
    .go__wrapper{
        width: 87.68354%;
    }
}
@media (min-width: 1400px) {
    .go__wrapper{
        width: 74.68354%;
    }
}


a{
    text-decoration: none;
}

ol, ul {
    list-style: none;
}






.hooks-main > svg {
    display: none;
}

.hooks-main > div {
    position: absolute;
    will-change: transform;
    border-radius: 50%;
    background: #1536E3;
    box-shadow: 10px 10px 5px 0px rgb(21, 54, 227);
    opacity: 0.6;
}

.hooks-main > div:nth-child(1) {
    width: 60px;
    height: 60px;
}

.hooks-main > div:nth-child(2) {
    width: 100px;
    height: 100px;
}

.hooks-main > div:nth-child(3) {
    width: 120px;
    height: 120px;
}

.hooks-main > div::after {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgba(21, 54, 227, 0.48);
}

.hooks-main > div:nth-child(2)::after {
    top: 35px;
    left: 35px;
    width: 35px;
    height: 35px;
}

.hooks-main > div:nth-child(3)::after {
    top: 25px;
    left: 25px;
    width: 25px;
    height: 25px;
}

.hooks-main {
    position: absolute;
    width: 100vw;
    height: 100vh;
    filter: url('#goo');
    overflow: hidden;
    top: 0;

}

.text-start {
    text-align: start;
    display: flex;
    justify-content: center;
}
.MuiFormLabel-root.Mui-focused{
    color: rgba(0, 0, 0, 0.72) !important;
}
p{
    line-height: 1.3;

}
#lottie{
    height: 20vh;
}
svg.scrollDown{
    width: 34px;
    position: absolute;
    bottom: 10px;
    left: calc(50% - 17px);
    opacity: 0.3 ;
    
}

.section.fp-section.fp-table svg.scrollDown{
    animation: intro-Y-Scroll  500ms forwards;
}
.section.fp-section.fp-table.active.fp-completely svg.scrollDown {
    animation: introY-Scroll  500ms forwards;

}


.section.fp-section.fp-table.active.fp-completely svg.scrollDown rect.dot {
    animation: scrollDot 2s infinite cubic-bezier(0.87, 0.11, 0.64, 1.18);

}
.fp-section .fp-overflow{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.MuiFormControl-root fieldset{
    border: 1px solid black !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-radius: 0 !important;

}
.MuiFormControl-root .MuiTextField-root  fieldset{
    border-top: 0 !important;
    border-bottom: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-radius: 0 !important;

}
.MuiFormControl-root label.MuiFormLabel-root{
    left: -13px;
}