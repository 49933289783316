.footer {
    height: 200px;
    background: #000610;
    color: #ffffff;
    width: 100%;
}

.footer .footer__wrapper {
    /*width: 74.68354%;*/
    margin-left: auto;
    margin-right: auto;
    display: flex;
    height: 100%;
    align-items: center;
}

.footer .footer__wrapper .brand {
    width: 50%;
    display: flex;

}

.footer .footer__wrapper .brand .brand__logo {
    font-size: 20px;
    display: flex;
    align-items: center;
}

.footer .footer__wrapper .menu {
    width: 50%;
    text-align: end;
}

.footer .footer__wrapper .brand .brand_slogan {
    display: flex;
    align-items: center;
    margin-left: 5px;
    font-size: 15px;
}

.brand_slogan .slogan--bold {
    font-weight: bold;
    margin-left: 5px;
}

.brand__logo .title--bold {
    font-weight: bold;
}

.brand__logo .logo svg{
    margin-right: 15px;
    height: 50px;
}

.menu .menu__wrapper .menu__link {
    font-size: 14px;
    color: #ffffff;
    text-decoration: none;
    margin-left: 15px;

}

.copyright__wrapper {
    font-size: 12px;
    color: #8b8b8c;
    margin-top: 10px;
    font-weight: 500;
    font-family: "roboto";
}





/*Responsive breakpoints*/
/*// Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {

    .logo__title {
        font-size: 20px;
    }

    .footer .footer__wrapper .brand .brand_slogan {
        font-size: 15px;
    }

    .brand__logo .logo svg{
        height: 42px;
        margin-left:-15px;
        margin-right:0px;
    }

    .menu .menu__wrapper .menu__link {
        font-size: 15px;
        margin-left: 0;
        margin-right: 10px;
    }
}

/*// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199.98px) {}

/*// Extra large devices (large desktops, 1200px and up)*/
/*@media (min-width: 1200px) { */
/*    */
/*}*/


@media only screen and (max-width: 1000px) {
    .footer .footer__wrapper {
        flex-direction: column;
        justify-content: center;
    }

    .footer .footer__wrapper .menu {
        width: 100%;
        text-align: start;

    }

    .footer .footer__wrapper .brand {
        width: 100%;
        margin-bottom: 20px;
    }
}