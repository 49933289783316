
.tag__number{
    font-size: 1.375rem;
    color: #fff;
    font-weight: bold;
    position: fixed;
    left: 4.5vw;
    z-index: 9;
    top: calc(20vh + 4px);


}


.fullPage__background{


    position: fixed;
    top: 0px;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: all 20ms cubic-bezier(.44,.55,.65,.77);
    /*opacity: 0;*/
    filter: blur(0.5px);

}
/*.fade-out {*/
/*    -webkit-animation: fade-out 500ms cubic-bezier(.44,.55,.65,.77);*/
/*    animation: fade-out 500ms ease-out ;*/
/*    !*filter: blur(3px);*!*/
/*}*/


@-webkit-keyframes fade-out {
    0% {
        opacity: 1;
    }
    20%{
        opacity: 0.5;
    }
    60% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-out {
    0% {
        opacity: 1;
    }
    20%{
        opacity: 0.5;
    }
    60% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
